import React, { useState, useEffect, useCallback } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators
} from "reactstrap";

export default function Hero({ items }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const onExiting = () => setAnimating(true);
  const onExited = () => setAnimating(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const next = useCallback(() => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  })

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  useEffect(() => {
    const interval = setTimeout(() => {
      next()
    }, 4000)

    return function cleanup() {
      clearInterval(interval);
    }
  }, [next])

  const slides = items.map((item, index) => {
    return (
      <CarouselItem 
        key={index}
        tag="div" 
        onExiting={onExiting} 
        onExited={onExited}> 
        <div
          className="carousel-image"
          style={{
            backgroundImage: `url(${item.src})`
          }}
        />
        <div className="carousel-caption">
          <h3>{item.caption}</h3>
          <p>{item.text}</p>
        </div>
      </CarouselItem>
    );
  });

  return (
    <div className="inicio">
      <Carousel interval={4000}  activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Anterior"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Próximo"
          onClickHandler={next}
        />
      </Carousel>
      </div>
  );
}
