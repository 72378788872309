import React from "react";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => (
  <footer data-aos="fade-right">
    <Container>
      <Row>
        <Col md="4">
          <h5>Contato</h5>
          <ul>
            <li>
              Telefone : <a href="tel:5511949259666">(11) 94925-9666</a>
            </li>
            <li>
              E-mail :
              <a href="mailto:jlvictorglass@gmail.com">
                {" "}
                jlvictorglass@gmail.com
              </a>
            </li>
          </ul>
        </Col>
        <Col md="4">
          <h5>Redes Sociais</h5>
          <ul>
            <li>
              <a href="https://www.facebook.com/glassvic/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} size="1x" /> Facebook
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/victorglass_/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} /> Instagram
              </a>
            </li>
          </ul>
        </Col>
        <Col md="4">
          <Link to="/trabalhe-conosco" className="h5">
            Ganhe Dinheiro na Victor Glass. Clique aqui
          </Link>
          <p>@ 2019 - Todos os direitos reservados.</p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
