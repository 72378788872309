import React, { useState, useEffect } from "react";

import { Redirect } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

import Navbar from "components/Navbar";
import WhatsApp from "components/WhatsApp";
import Footer from "components/Footer";

import firebase from "firebase/app";

import Seo from "components/Seo";
import data from "constants/data.js";

const projects = ["box", "espelhos", "sacadas", "fechamentos"];

const getDownloadUrls = files => {
  const downloadURLs = files.items.map(item => item.getDownloadURL());
  return downloadURLs;
};

const resolveDownloadURLs = async downloadURLs => {
  const urls = await Promise.all(downloadURLs);
  return urls;
};

const showError = error => {
  console.log(error);
};

const RenderImages = ({ images, name }) => {
  let columns = [[], [], []];
  let actual = 0;

  images.forEach(image => {
    columns[actual % columns.length].push(image);
    actual++;
  });

  return (
    <Row>
      {columns.map((column, index) => (
        <Col key={index} lg="4" md="12" className="mb-2">
          {column.map((image, index) => (
            <img
              key={index}
              src={image}
              className="img-fluid rounded shadow mb-4"
              alt={name}
              data-aos="fade-in"
            />
          ))}
        </Col>
      ))}
    </Row>
  );
};

const Works = ({ match }) => {
  const name = match.params.name;
  const [images, setImages] = useState([]);
  const currentSeo = data(name);

  useEffect(() => {
    if (!projects.includes(name)) return;

    const storage = firebase.storage();
    const ref = storage.ref(name);

    const addURLs = urls => {
      setImages(urls);
    };

    ref
      .listAll()
      .then(getDownloadUrls)
      .then(resolveDownloadURLs)
      .then(addURLs)
      .catch(showError);
  }, [name]);

  if (!projects.includes(name)) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <Seo {...currentSeo} />
      <Navbar fill />
      <WhatsApp />
      <main>
        <Container>
          <h1 className="h4">PROJETOS - {name.toUpperCase()}</h1>
          <RenderImages images={images} name={name} />
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default Works;
