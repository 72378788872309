import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import "assets/scss/victor-glass.scss";

// Views
import Home from "views/Home";
import Works from "views/Works";
import WorkWithUs from "views/WorkWithUs";
import NotFound from "views/NotFound";

// Firebase import
import firebase from "firebase/app";
import "firebase/firebase-firestore";
import "firebase/firebase-storage";
import "firebase/firebase-analytics";

import AOS from "aos";
import "aos/dist/aos.css";

// Firebase initialization
firebase.initializeApp({
  apiKey: "AIzaSyCjD8wVEYF_lBBkatSvWsjRlCt4z6Jnlho",
  authDomain: "victor-glass-48baa.firebaseapp.com",
  databaseURL: "https://victor-glass-48baa.firebaseio.com",
  projectId: "victor-glass-48baa",
  storageBucket: "victor-glass-48baa.appspot.com",
  messagingSenderId: "266210232752",
  appId: "1:266210232752:web:ac605e7794bee691596e75",
  measurementId: "G-XMJQRC7XRF"
});

AOS.init({
  offset: 50
});

firebase.analytics();

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={props => <Home {...props} />} />
      <Route path="/trabalhe-conosco" render={props => <WorkWithUs {...props} />} />
      <Route path="/projetos/:name" render={props => <Works {...props} />} />
      <Route path="/404" render={props => <NotFound {...props} />} />
      <Redirect from="*" to="/404" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
