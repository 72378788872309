import React, { useEffect } from "react";
import Navbar from "components/Navbar";
import WhatsApp from "components/WhatsApp";

import { Container, Row, Col } from "reactstrap";

import Seo from "components/Seo";
import data from "constants/data";

import Footer from "components/Footer"

const WorkWithUs = () => {
  const currentSeo = data("trabalhe");

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [])

  return (
    <>
      <Seo {...currentSeo} />
      <Navbar fill />
      <WhatsApp />
      <main>
        <Container>
          <h1 className="h2">Ganhe Dinheiro na Victor Glass</h1>
          <Row className="mt-5">
            <Col md="6">
              <p>
                É muito fácil ganhar dinheiro com a Victor Glass. É só entrar em
                contato conosco e dizer que quer ser um Vendedor. Fornecemos o
                material e tudo que você precisa saber para realizar suas
                vendas. Total suporte aos nossos vendedores. Comece
                imediatamente entrando em contato conosco através do whatsapp.
              </p>

              <p>
                <strong>Arquitetos</strong>: RRT de 10% para você arquiteto.
                Garantimos a qualidade e agilidade do serviço. Temos parceria
                com mais de 20 arquitetos, e clientes completamente satisfeitos.
                Faça uma cotação conosco, envie seu projeto que orçaremos para
                você.
              </p>

              <p>
                <strong>Clientes</strong>: Se você já fez um serviço conosco, a
                cada pessoa indicada, há uma bonificação de 3% no valor total do
                pedido para você. Entre em contato conosco e diga o nome
                endereço da pessoa que você indicou.
              </p>
            </Col>
            <Col md="6">
              <img
                data-aos="fade-bottom"
                className="img-fluid rounded shadow"
                src="https://images.unsplash.com/photo-1535209039648-9524289505fd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=749&q=80"
                alt="Trabalhe em casa"
              />
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default WorkWithUs;
