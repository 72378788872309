import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import firebase from "firebase/app"

const WhatsApp = () => {
  const analytics = firebase.analytics()
  const [visibility, setVisibility] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setVisibility("show");
      setTimeout(() => {
        setVisibility("hidden");
      }, 10000);
    }, 5000);
  }, []);

  const logEvent = (e) => {
    analytics.logEvent("click_whatsapp");
  };

  return (
    <div className="whatsapp-chat">
      <span className={visibility}>
        <p>Entre em contato conosco.</p>
      </span>
      <a
        onClick={logEvent}
        className="whatsapp-button"
        data-aos="zoom-in-up"
        data-aos-once="true"
        data-aos-offset="0"
        href="https://api.whatsapp.com/send?l=pt_br&phone=5511949259666&text=Ol%C3%A1,%20preciso%20de%20um%20or%C3%A7amento"
      >
        <FontAwesomeIcon icon={faWhatsapp} color="white" size="2x" />
      </a>
    </div>
  );
};

export default WhatsApp;
