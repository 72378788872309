import React, { useState, useEffect } from "react";

// Components
import Navbar from "components/Navbar";
import Hero from "components/Hero";
import WhatsApp from "components/WhatsApp";

// SEO
import Seo from "components/Seo";
import data from "constants/data";

import firebase from "firebase/app";

const Landing = () => {
  const [images, setImages] = useState([]);
  const currentSeo = data("home");
  const database = firebase.firestore();

  const queryImages = querySnapshot => {
    const imagesFromDB = [];
    querySnapshot.docs.forEach(doc => {
      imagesFromDB.push(doc.data());
    });

    return imagesFromDB;
  };

  const addImages = imagesFromDB => {
    imagesFromDB.sort((a, b) => a.order > b.order ? +1 : -1);
    setImages(imagesFromDB);
  };
  
  const showError = error => console.error(error);

  useEffect(() => {
    database
      .collection("carousel")
      .get()
      .then(queryImages)
      .then(addImages)
      .catch(showError);
  }, [null]);

  return (
    <>
      <Seo {...currentSeo} />
      <Navbar />
      <WhatsApp />
      <Hero items={images} />
    </>
  );
};

export default Landing;
