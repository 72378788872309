import React, { useState } from "react";
import {
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavItem,
  NavLink,
  Container
} from "reactstrap";

import { NavLink as NavActive } from "react-router-dom";
import Logo from "assets/images/logo.png";

import firebase from "firebase/app"

const MainNavbar = ({ fill }) => {
  const analytics = firebase.analytics()
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const navigate = () => {
    if (isOpen) {
      setIsOpen(false);
    }

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    analytics.logEvent(`click_${document.URL.split('/')[4]}`);
  };

  return (
    <Navbar
      dark={!fill}
      light={fill}
      expand="md"
      fixed="top"
      style={{
        backgroundColor: fill ? "#fff" : "rgba(0, 0, 0, 0.3)",
        boxShadow: fill ? "0 0 10px rgba(0,0,0, .2)" : "none"
      }}
    >
      <Container>
        <NavbarBrand tag={NavActive} to="/">
          <div className="logo-glass">
            <img src={Logo} alt="Logo" width="100" data-aos="flip-left" />
            <p style={{ color: fill ? "black" : "white" }}>VICTOR GLASS</p>
          </div>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />

        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar data-aos="flip-up">
            <NavItem onClick={navigate}>
              <NavLink tag={NavActive} to="/projetos/box">
                BOX
              </NavLink>
            </NavItem>
            <NavItem onClick={navigate}>
              <NavLink tag={NavActive} to="/projetos/espelhos">
                ESPELHOS
              </NavLink>
            </NavItem>
            <NavItem onClick={navigate}>
              <NavLink tag={NavActive} to="/projetos/sacadas">
                SACADAS
              </NavLink>
            </NavItem>
            <NavItem onClick={navigate}>
              <NavLink tag={NavActive} to="/projetos/fechamentos">
                FECHAMENTOS EM VIDROS
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNavbar;
