import React from "react";
import Navbar from "components/Navbar";
import WhatsApp from "components/WhatsApp";

import { Container, Button } from "reactstrap";
import Seo from "components/Seo";

const NotFound = ({ history }) => {
  return (
    <>
      <Seo
        title="Conteúdo solicitado não encontrado."
        robots="noindex, nofollow"
      />
      <Navbar fill />
      <WhatsApp />
      <main>
        <Container>
          <div
            style={{ height: 450 }}
            className="d-flex flex-column justify-content-center"
          >
            <div className="text-center">
              <h1 className="text-warning">Ops...</h1>
              <h2>Conteúdo solicitado não encontrado.</h2>
              <Button
                className="mt-2"
                color="warning"
                onClick={() => history.goBack()}
              >
                Voltar
              </Button>
            </div>
          </div>
        </Container>
      </main>
    </>
  );
};

export default NotFound;
