import React from "react";
import { Helmet } from "react-helmet";

const Seo = props => (
  <Helmet title={props.title} titleTemplate="%s | Victor Glass">
    <meta name="description" content={props.description} />
    <meta name="keywords" content={props.keywords} />
    <meta name="language" content="pt-BR" />
    <meta name="country" content="BRA" />
    <meta name="currency" content="R$" />
    <meta name="copyright" content="Victor Glass" />
    <meta name="robots" content={props.robots} />
    <meta name="googlebot" content={props.robots} />
    <meta name="google" content="nositelinkssearchbox" />
    <meta property="og:type" content="website" />
    {props.ogUrl && <meta property="og:url" content={props.ogUrl} />}
    {props.ogTitle && <meta property="og:title" content={props.ogTitle} />}
    {props.ogDescription && 
      <meta property="og:description" content={props.ogDescription} />
    }
    {props.ogImage && <meta property="og:image" content={props.ogImage} />}
    {props.ogLocate && <meta property="og:locale" content="pt-BR" />}
  </Helmet>
);

export default Seo;
