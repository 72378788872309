const home = {
  title: "Envidraçamento de Sacada, Box, Espelhos, Fechamentos em Vidro - O Melhor Preço para Você",
  description:
    "Envidraçamento de Sacada, Box, Espelhos, Fechamentos em Vidro - O Melhor Preço para Você",
  keywords:
    "vidraçaria, vidraçaria zona sul, box zona sul, box de banheiro, orçamento box, orçamento box zona sul, box blindex, sacada, envidraçamento de sacada, envidraçamento de sacada zona sul, espelhos, espelhos zona sul, box barato, orçamento, orçamento grátis, entrega rapida, envidraçamento barato, sacada retrátil, fechamento de varanda, fechamento de varanda zona sul, fechamento de sacada centro, melhor preço sacada, melhor preço box, instalação de box, victor glass, victor glass vidraçaria, box de banheiro apartamento, espelho bisote, espelho lapidado, espelho banheiro, espelho sala, espelho quarto, espelhos, vidraceiro, vidro,vidraceiro na minha cidade, vidraceiros em sao paulo, vidraceiro em sp, orçamento vidraçaria, serviço de vidraçaria, orçamento vidraceiro gratuito, preço de instalação de box, preço de instalação de sacada, fechamentos em vidros",
  robots: "index, follow",
  ogUrl: "https://www.victorglass.com.br",
  ogTitle: "Victor Glass",
  ogDescription:
    "Envidraçamento de Sacada, Box, Espelhos, Fechamentos em Vidro - O Melhor Preço para Você",
  ogImage:
    "https://firebasestorage.googleapis.com/v0/b/victor-glass-48baa.appspot.com/o/outros%2F52544888_224389658386933_6874211082710286336_n.jpg?alt=media&token=26ef9913-a93f-458f-a382-9d10ecca9f89"
};

const trabalhe = {
  title: "Ganhe Dinheiro na Victor Glass",
  description:
    "É muito fácil ganhar dinheiro com a  Victor Glass. É só entrar em contato conosco e dizer que quer ser um Vendedor. Fornecemos o material e tudo que você precisa saber para realizar suas vendas. Total suporte aos nossos vendedores. Comece imediatamente entrando em contato conosco através do whatsapp.",
  keywords:
    "ganhar dinheiro em casa, ganhar dinheiro, RRT, comissão, fazer venda online",
  robots: "index, follow",
  ogUrl: "https://www.victorglass.com.br/trabalhe-conosco",
  ogTitle: "Ganhe Dinheiro na Victor Glass",
  ogDescription:
    "É muito fácil ganhar dinheiro com a Victor Glass. É só entrar em contato conosco e dizer que quer ser um Vendedor. Fornecemos o material e tudo que você precisa saber para realizar suas vendas. Total suporte aos nossos vendedores. Comece imediatamente entrando em contato conosco através do whatsapp.",
  ogImage:
    "https://images.unsplash.com/photo-1535209039648-9524289505fd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=749&q=80"
};

const box = {
  title: "Instalação de Box Zona Sul – Melhor Preço",
  description:
    "Box para Banheiro a Pronta Entrega – Box  Zona Sul com o Melhor Preço – Faça já seu Orçamento Gratuitamente – Preços Promocionais – Sacadas, Espelhos, Box, Fechamentos em Vidro temperado, Janela de Vidro, Envidraçamento de Sacada",
  keywords:
    "vidraçaria, vidraçaria zona sul, box de banheiro, orçamento box, orçamento box zona sul, box blindex, box barato, orçamento, orçamento grátis, entrega rapida, melhor preço, box, instalação de box, box de banheiro apartamento, preço de instalação de box",
  robots: "index, follow",
  ogUrl: "https://www.victorglass.com.br/projetos/box",
  ogTitle: "Instalação de Box Zona Sul – Melhor Preço",
  ogDescription:
    "Box para Banheiro a Pronta Entrega – Box  Zona Sul com o Melhor Preço – Faça já seu Orçamento Gratuitamente",
  ogImage:
    "https://firebasestorage.googleapis.com/v0/b/victor-glass-48baa.appspot.com/o/box%2FIMG-2876.jpg?alt=media&token=67c55ed0-c344-4c17-a6ba-cc98cfb9c5ee"
};

const sacadas = {
  title: "Envidraçamento de Sacada Zona Sul – Faça seu Orçamento Já",
  description:
    "Empresa Especializada em Envidraçamento de Sacadas e Varanda – Fechamento de Sacada Zona Sul – Envidraçamento de Sacada com o Melhor Preço- Box para Banheiro a Pronta Entrega – Box  Zona Sul – Faça já seu Orçamento Gratuitamente – Preços Promocionais – Sacadas, Espelhos, Box, Fechamentos em Vidro temperado, Janela de Vidro, Envidraçamento de Sacada",
  keywords:
    "vidraçaria, vidraçaria zona sul, sacada, envidraçamento de sacada, envidraçamento de sacada zona sul, orçamento, orçamento grátis, entrega rapida, envidraçamento barato, sacada retrátil, fechamento de varanda, fechamento de varanda zona sul, fechamento de sacada centro, melhor preço sacada, vidraceiro na minha cidade,vidraceiros em sao paulo, vidraceiro em sp, orçamento vidraçaria, preço de instalação de sacada",
  robots: "index, follow",
  ogUrl: "https://www.victorglass.com.br/projetos/sacadas",
  ogTitle: "Envidraçamento de Sacada Zona Sul – Faça seu Orçamento Já",
  ogDescription: "Empresa Especializada em Envidraçamento de Sacadas e Varanda – Fechamento de Sacada Zona Sul",
  ogImage: "https://firebasestorage.googleapis.com/v0/b/victor-glass-48baa.appspot.com/o/sacadas%2FIMG-6327.jpg?alt=media&token=fe8f0951-b2d9-4a3a-b535-f56fedfadbbc"
};

const espelhos = {
  title:
    "Espelhos  Zona Sul – O melhor Espelho e M elhor Preço - Faça seu Orçamento Já",
  description:
    "Espelhos Zona Sul – O Melhor Preço para Espelho Lapidado e Bisotê – Produção e Instalação de Espelho-  Entrega Rápida - Box para Banheiro a Pronta Entrega – Box  Zona Sul – Faça já seu Orçamento Gratuitamente – Preços Promocionais – Sacadas, Espelhos, Box, Fechamentos em Vidro temperado, Janela de Vidro, Envidraçamento de Sacada",
  keywords:
    "vidraçaria, vidraçaria zona sul, espelhos, espelhos zona sul, orçamento, orçamento grátis, espelho bisote, espelho lapidado, espelho banheiro, espelho sala, espelho quarto, espelhos, vidraceiro, vidro,vidraceiro na minha cidade, vidraceiros em sao paulo",
  robots: "index, follow",
  ogUrl: "https://www.victorglass.com.br/projetos/espelhos",
  ogTitle: "Espelhos Zona Sul – O melhor Espelho e Melhor Preço - Faça seu Orçamento Já",
  ogDescription: "Espelhos Zona Sul – O Melhor Preço para Espelho Lapidado e Bisotê – Produção e Instalação de Espelho",
  ogImage: "https://firebasestorage.googleapis.com/v0/b/victor-glass-48baa.appspot.com/o/espelhos%2FIMG-7224.JPG?alt=media&token=57daed18-6d64-4f91-8feb-4161dd95e297"
};

const fechamentos = {
  title:
    "Fechamento em Vidro Zona Sul – O Melhor Preço e Entrega Rápida - Faça seu Orçamento Já",
  description:
    "Janelas de Vidro Temperado – Fechamento de Área de Serviço em Vidro Temperado – Fechamento de Vidro  Zona Sul – Faça Seu Orçamento Gratuitamente - Entrega Rápida - Box para Banheiro a Pronta Entrega – Box  Zona Sul – Preços Promocionais – Sacadas, Espelhos, Box, Fechamentos em Vidro temperado, Janela de Vidro, Envidraçamento de Sacada",
  keywords:
    "vidraçaria, vidraçaria zona sul, envidraçamento de sacada, orçamento, orçamento grátis, instalação de box, vidraceiro, vidro,vidraceiro na minha cidade, vidraceiros em sao paulo, vidraceiro em sp, orçamento vidraçaria, serviço de vidraçaria, orçamento vidraceiro gratuito, preço de instalação de box, preço de instalação de sacada",
  robots: "index, follow",
  ogUrl: "https://www.victorglass.com.br/projetos/fechamentos",
  ogTitle: "Fechamento em Vidro Zona Sul  –  O Melhor Preço e Entrega Rápida - Faça seu Orçamento Já",
  ogDescription: "Janelas de Vidro Temperado – Fechamento de Área de Serviço em Vidro Temperado – Fechamento de Vidro  Zona Sul",
  ogImage: "https://firebasestorage.googleapis.com/v0/b/victor-glass-48baa.appspot.com/o/fechamentos%2FIMG-7230.JPG?alt=media&token=8ab9c1b0-0c97-4672-903d-3830ff1d1a05"
};

export default category => {
  switch (category) {
    case "home":
      return home;
    case "trabalhe":
      return trabalhe;
    case "box":
      return box;
    case "sacadas":
      return sacadas;
    case "espelhos":
      return espelhos;
    case "fechamentos":
      return fechamentos;
    default:
      return {};
  }
};
